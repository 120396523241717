.tog {
    margin-top: 10px;
    padding-top: 10px;

}
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: black;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px white;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
/* @@@@@@@@@@@@@@@@@ end slider @@@@@@@@@@@@@@@@ */

  /* tr {
    height: 57px;

    border-radius: 4px;
  }

  /* .thclaimed {
    width: 140px;
  }
  .thclaimed {
    display: none;
  }
  .thclaimed2 {
    margin: 5px 0px 5px 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  a {
    color: aqua;
    text-decoration: underline;
  }
  .infoButton {
    margin-left: 0px !important;
    padding: 3px 3px 3px 3px;
    width: 25px;
  }

  .cbtd {
    margin: 0px !important;
    padding: 0px !important;
    width: 35px;

    font-size: 1.5rem;

  }
  .cb {
    margin: 0px !important;
    padding: 0px !important;
    width: 20px;
  }

  th {
    padding: 2.5px;
  }


  .requesteeTd {
    font-size: 1.25rem;
    overflow-x: hidden;
    text-overflow: ellipsis;
    width: 57px;

  }
  .editSpan {
    display: flex;
    margin-left: auto;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
  }

.giftNametd {
  text-align: left;
  font-size: 1.75rem;
  display: flex;
  flex-wrap: wrap;
  text-overflow: ellipsis;
  width: auto;

  justify-content: center;
  align-items: center;
  margin-top: 12px;
  border-radius: 4px;
   margin-left: 5px;
} */
/* #giftsTable > tbody > tr > td > b {
  max-width: 100px;
  text-overflow: ellipsis;
  /* word-wrap: break-word;
} */



/* @media screen and (min-width: 600px) {
  .giftNametd {
    font-size: 2.5rem;
  }
  .requesteeTd {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 600px) {
  #giftsTable {
      width: 96vw;
      margin-left: 0;
  }
}
@media only screen and (max-width: 460px) {
  .giftNametd {
    margin-left: 5px;
    font-size: 1.5rem;
  }
  .editSpan {
    margin: 0;
  }
  #giftsTable > tbody > tr > td > b {
  max-width: 130px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  }
} */

/* claim button table data */
  /* td > .cbtd {
    width: 25px;
    padding: 5px;
    margin: 5px;
  }
  .cbtd > input {
    width: 25px;
  }

  tr > .firstTableRow {
    border-radius: 4px;
  } */


  /* .firstTableRow {

    background-color: rgba(220, 20, 60, .25);
    border-radius: 4px;

  }
  .firstTableRow > td {
    border-radius: 4px;
  } */

  /* .secondTableRow {

    background-color: rgba(245, 245, 245, .75);
  } */
  /* .secondTableRow > td {
    border-radius: 4px;
  }

  .tooltip {
    position: relative;
    background: rgba(0, 0, 0, .7);
    color: whitesmoke;
    display: block;
  } */

  /* .giftContainer {
    width: 70vw;
    height: 50vh;
    margin-left: 15vw;
  }

  #giftsTable {
    width: 60vw;
  }
  table > tbody {
    /* width: 50%;
  }
  table > tbody > tr > td {
    height: 67px;
}

  /* .giftContainer > td > table > tbody > tr > td {
    margin: 0px 15px 0px 15px;

  }

  #rowForHeaders {
    border: 1px solid #dc143c;
  } */

  /* table {
    /* margin: 0px 2px 0px 2px; */
    /* width: 70%;
    height: 100%; */
  /* }  */

  /* #imgDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;


  }
  #imgDiv {
    margin-right: 3px;
    position: relative;
    display: flex;

  }
  #dot {
    background-color: green;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    z-index: 5;
    position: absolute;

  } */

  /* .imageCropper {
    border-radius: 50%;
    overflow: hidden;
    width: 25px;
    height: 25px;
    position: absolute;
    display: flex;
    justify-content: flex-start;

  } */

/* #textEditIn1 {
  /* font-family: 'Tangerine', cursive;
  font-size: 2.25rem;
}
#textEditIn2 {
  /* font-family: 'Tangerine', cursive;
  font-size: 2.25rem;
}
/* #textEditIn3 {
  /* font-family: 'Tangerine', cursive;
  font-size: 2.25rem;
}
/* .splitLink {
  width: 50px;
}
#splitButton {
  border: 1px dotted crimson;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}


/* .giftLinkTd {
  width: 15px;
}

svg {
  pointer-events: none;
} */