.usernameFormContainer {
    width: 250px;
    padding: 13px 0px 25px 0px;
    
}

.usernameIn {
    width: 250px;
}

.usernameFormContainer > form {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.usernameFormContainer > form > button {
    width: 150px;
}