.dashboardTainer {
    height: 100%;
}

#verifyemailBtn {
    min-height: 80px;
    padding: 1px;
}

#welcomeh2 {
    
}