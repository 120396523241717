#file {
    width: 200px;
    height: 20px;
    color: crimson;
    font-family: 'Tangerine', cursive;
    font-size: 16px;
}
.avatarContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px 0px 12px 0px;
}
input[type='file'] {
    display: none;
}
.label {
    border: 1px solid #ccc;
    border-radius: 3px;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}