@tailwind base;
@tailwind components;
@tailwind utilities;




/* .swalCustomClass {
    @apply w-[95%] sm:w-[300px] bg-pink-700;
}

.swalConfirmBtn {
    @apply btn btn-primary hover:bg-secondary;
}


.swal2-show {
    @apply w-[95%] sm:w-[300px] bg-pink-700;
}
.swal2-modal > button {
    @apply btn btn-info;
}
.swal2-popup > button {
    @apply w-[15%] bg-pink-700;
} */

/* quite sure these are swal styles - 08 23 2024 */

#textEditIn1 {
    /* font-family: 'Tangerine', cursive; */
   @apply  input my-2 input-bordered text-lg w-full;
}

#textEditIn2 {
    /* font-family: 'Tangerine', cursive; */
   @apply  input my-2 input-bordered text-lg w-full;
}
#textEditIn3 {
    /* font-family: 'Tangerine', cursive; */
   @apply  input my-2 input-bordered text-lg w-full;
}
/* .swal2-modal {
    @apply w-[95%] sm:w-[300px] bg-pink-700;
} */
/* .swal2-html-container {
    @apply bg-primary;
} */
/* .swal2-title {
    @apply bg-primary;
} */
.swal2-popup.swal2-modal.swal2-show {
    @apply bg-base-100;
}

.swal2-popup.swal2-modal.swal2-show button {
    @apply btn btn-lg;
}

.swal2-popup.swal2-modal.swal2-show > input {
    @apply checkbox checkbox-primary checkbox-lg;
}

label.swal2-checkbox {
    @apply h-24 bg-base-100;
}
input#swal2-checkbox {
    @apply checkbox checkbox-primary checkbox-lg bg-base-100 m-0;
}

.swal2-popup.swal2-modal.swal2-show > input {
    @apply w-72 mx-auto cursor-text input input-bordered;
}

html {
    @apply h-full;
}

body {
    @apply h-full;
}



/* .swal2-confirm {
    @apply  w-24;
} */
/* .swal2-confirm {
    color: greenyellow;
    background-color: teal;
    width: 300px;
    border-radius: 50%;
    height: 100px;
} */
/* .swal2-styled {
    color: greenyellow;
    background-color: teal;
} */


/* overlay */
/* .swal2-shown {
    @apply  bg-pink-700;
} */

/* edges around confirm button */
/* .swal2-actions {
    @apply w-[95%] sm:w-[300px] bg-none;
}
*/

